import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import InfoWindow from './MapSurveyInfoWindow';


class MapOverDrillMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };

    this.handleClick = this.handleClick.bind(this);
  }


  handleClick(event) {
    console.log("MapOverDrillMarker:handleClick:",this.props.surveyInfo);
  }


  render() {
    let backgroundColor = 'lightgrey' ;
    let textColor = 'black' ;
    let border = '1px solid black' ;
    let radius=20;

    let overDrillDiff = null;

    if ((this.props.layerOptions === '1') && (this.props.surveyInfo.overDrillPD!=null)) overDrillDiff=Math.round(parseFloat(this.props.surveyInfo.overDrillPD));
    if ((this.props.layerOptions === '2') && (this.props.surveyInfo.overDrillPL!=null)) overDrillDiff=Math.round(parseFloat(this.props.surveyInfo.overDrillPL));

    let depthTol = parseFloat(this.props.surveyInfo.depthTol);

    if (overDrillDiff!==null) {
      if(overDrillDiff > depthTol){
        backgroundColor='red';
        radius = Math.max((overDrillDiff-depthTol)*3, 20);
        textColor = 'white';
      } else if (overDrillDiff < depthTol*-1) {
        backgroundColor='blue';
        radius = Math.max((overDrillDiff + depthTol)*-3, 20);
        textColor = 'white';

        if(overDrillDiff < -90) { //Collapsed for all practical purposes
          backgroundColor='black';
          textColor = 'white';
          radius    = 20;
        }

      } else {
        backgroundColor='green';
        textColor = 'white';
      }

      if ((this.props.layerOptions === '1') && ((this.props.surveyInfo.postDrillHoleState & 0x02) > 0 )){
        backgroundColor='black';
        textColor = 'white';
        radius    = 20;
      }

      if ((this.props.layerOptions === '2') && ((this.props.surveyInfo.preLoadHoleState & 0x02) > 0 )){
        backgroundColor='black';
        textColor = 'white';
        radius    = 20;
      }
    }

    //if (radius<15) textColor = 'black' ;

    if (radius > 100 ) radius=100;
   
    const markerStyle = {
      border: (this.props.$hover||this.props.selected)?'3px solid fuchsia':border,
      borderRadius: '50%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: (this.props.$hover||this.props.selected)?radius+6:radius,
      width:  (this.props.$hover||this.props.selected)?radius+6:radius,
      backgroundColor: (this.props.$hover||this.props.selected)?'fuchsia':backgroundColor,
      cursor: 'pointer',
      zIndex: radius,
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    const textStyle = {
      color: (this.props.$hover||this.props.selected)?'white':textColor,
      fontSize: (this.props.$hover||this.props.selected) ? 12:11,
      fontWeight:( this.props.$hover||this.props.selected) ? 'bold':'normal',
    };

    return (
      <div>
        <div 
          style={markerStyle}  
          onClick={this.handleClick}> 
          <span style={textStyle}>{this.props.surveyInfo.holeNo ? this.props.surveyInfo.holeNo:''}</span> 
        </div>
        {(this.props.$hover) && //hover but not clicked OR Clicked
         <InfoWindow
            hole              = {this.props.surveyInfo}
            userDetails       = {this.props.userDetails}
            refreshData       = {this.props.refreshData}
            handleMarkerClear = {this.props.handleMarkerClear}
            handleClick       = {this.handleClick}
            loadAddEnabled    = {false}
            markerClickInfo   = {this.props.markerClickInfo} />}
      </div>
    );
  }
}

export default MapOverDrillMarker;

