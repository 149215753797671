export const TRANSLATIONS_FR = {
  Loading:"Loading",  //Show when waiting for data from Cloud Server 

  //NavBarA Menu and sub Menu items
  Reports:"Rapports", //"Reports"
  Setup:"Setup",
  Downloads:"Téléchargements",//"Downloads"
  Logout:"Logout",
  Login:"Login",
  Year: "Année", //"Year"
  Date_Range:"Plage de Dates",//"Date Range"
  Pattern: "Patron", //"Pattern",
  Pattern_Load_Summary:"Pattern Load Summary",
  Pump_Service:"Service de Pompe",//"Pump Service"
  TIZ:"TIZ",
  Speeding:"Excès de Vitesse",//"Speeding",
  Import_Pattern:"Importé Patron",//"Import Pattern"
  Notifications:"Notifications",
  People:"Gens",//"People",
  Products:"Produits",//"Products",
  Vehicles:"Véhicules",//"Vehicles",
  Zones:"Zones",
  Admin:"Admin",
  Company:"Company",
  Users:"Users",
  Admin_Users: "Setup Users",

  Weight_Pumped_per_Day:"Quantité Pompé par Jour",//"Weight Pumped per Day"
  Recent_Patterns:"Patrons Récent",//"Recent Patterns" //Table heading containing List of Recent patterns
  Daily_Summary:"Résumé Journalier", //Daily Summary

  //Report Names
  Pattern_Report:"Rapport de Patron", //"Pattern Report" //Heading for Pattern Report
  Date_Range_Report:"Rapport de Plage de Dates", //"Date Range Report",
  Time_In_Zone_Report:"Rapport Time In Zone(TIZ)", //"Time In Zone(TIZ) Report",

  //Column Headings. Use abbreviations and keep as short as possible
  Vehicle_Issues:"Problèmes de Véhicules", //"Vehicle Issues",//Possible problems with instalation in truck
  Vehicle:"Véhicule",//"Vehicle"//Vehicle Name or description
  Description:"Description", //General word for details
  Since:"Depuis", //"Since"//Since date
  Days_Ago:"Jours Passer",//"Days Ago"
  Last_Date:"Dernière Date",//"Last Date" //Typical Last date we received data
  Site:"Site", //Site Name
  No_Vehicles:"N° Véhicules",//"No Vehicles" //Number of Vehciles
  No_Holes:"N° Trous", //"No Holes"//Number of Holes`count
  No_Loads:"No Loads", //"No Holes"//Number of Holes`count
  No_Events:"N° Événements",//"No Events" //Number of Events count
  No_Days:"N° Jours",//"No Days"//Number of days count
  Loaded:"Chargée", //"Loaded"//As Loaded
  Drilled:"Drilled",//"Drilled"//As Drilled
  Survey:"Survey",//"Survey"//As Survey
  Design:"Design", //As per design
  Approved:"Approuvée",//"Approved" //Approved by
  Approved_Date:"Date Approuvée", //"Approved Date"//Date approval happened
  URL:"URL", //URL Link
  Approved_State:"État Approuvée",//"Approved State" //Is it approved
  Start_Date:"Date de Début",//"Start Date"
  End_Date:"Date de Fin",//"End Date",
  Select_Site:"Sélectionner un Site", //"Select Site"//In Dropdown when no Site Selected
  Select_Pattern:"Sélectionner un Patron",//"Select Pattern" //In Dropdown when no Pattern Selected
  Export_BlastX:"BlastX", //Button name for export data in BlastX format
  Export_CSV:"CSV", //Button name for export data in CSV format
  Export_PDF:"PDF", //Button name for export data in CSV format
  Export_Link:"Copier le Lien",//"Copy Link"//Button name to copy URL to clipboard
  Pattern_Approve:"Approuvée", //"Approve" //Button name to Approve pattern
  Pattern_Unlock:"Ouvrir",//"Unlock"//Button name to Unlock pattern
  Heatmap:"Carte de Chaleur", //"Heatmap"//Button name to taggle map Heatmap layer
  Over_Fill:"Déborder", //"OverFill"//Button name to toggle map OverFill layer
  Load_Progress:"Progrès du Chargement",//"LoadProgress"//Button name to toggle map loading Progress layer
  PNG:"PNG", //Button to create PNG image map of pattern
  Summary:"Résumé",//"Summary"
  Date:"Date",
  Duration:"Durée",//"Duration",
  Weight:"Poids",//"Weight"

  Media:"Média", //"Media",//Tabe header containg media video and photo files
  Filename:"Nom du Fichier",//"Filename"
  SizeKB:"Taille(KB)",//"Size(KB)" //file size

  //Detail Hole Information
  Id:"Id",
  Pattern_No:"N° Patron",//"Pattern No",
  Hole_No:"N° Trou", //"Hole No",
  Depth:"Profondeur",//"Depth",
  Stemming:"Stemming",
  Diameter:"Diamètre",//"Diameter",
  Spacing:"Spacing",
  Burden:"Burden",
  DateTime:"Date",
  Driver:"Conducteur", //"Driver",//Driver or if prefered Operater
  Blaster:"Blaster",
  Loads:"Chargements", //"Loads"//Number of Loads at this hole
  Decks:"Decks",//Numver of decks at this hole
  Density:"Densité", //"Density",//Product density
  Product:"Produit", //"Product", //Nmae of product
  Manual:"Manual", //Manual user load entry
  UnitWeight:"Unit Weight", //Stick weight of manual product
  UnitHeight:"Unit Height", //eg Bag height
  State:"État du Trou", //"State",//State of hole eg Wet cracked etc
  Notes:"Notes", //Free text notes for this hole
  Detail_Hole_Information:"Détails du Trou",//"Detail Hole Information" //Table Heading for detailed hole information per hole


  //MapInfoWindows
  noLoads:"N° Chargements", //"no Loads"//Number of loads at this hole(Duplicate)
  noDecks:"N° Decks",//"no Decks" //Number of Decks at this hole(Duplicate)
  noDays:"N° Jours",//"no Days"//Number of days
  noLoaded:"No Loaded",
  noDesign:"No Design",
  DeckNo:"N° Deck",//"Deck No"//Deck number of this deck
  OK:"OK",//Button Label
  Cancel:"Annuler",//"Cancel"//Button Label
  Done:"Fini",//"Done"//Button Label
  Close:"Fermé",//"Close"//Button Label
  Angle:"Angle",//Hole angle
  Column_Weight:"Poid de la Colonne",//"Column Weight",
  Column_Height:"Hauteur de la Colonne",//"Column Height",
  ProductType:"Type de Produit",//"Product Type",
  Decking_Height:"Hauteur du Deck",//"Decking Height",
  Decking_Type:"Type de Deck", //"Decking Type",
  Max_Speed:"Vitesse Maximale", //"Max Speed",
  Avg_Speed:"Vitesse Moyenne",//"Avg Speed",
  Speed_Limit:"Limite de Vitesse", //"Speed Limit",
  Zone:"Zone",//"Zone",
  Address:"Adresse",//"Address" //Phisical address where something happened
  Multi_Hole:"Multi Trous", //"Multi Hole",//Displayed when you editing more than one hole data at the same time. Instead of the hole number
  Selected_loads:"Trou Sélectionner",//"Selected Hole",

  //Year Report
  Year_Report:"Rapport Annuel", //"Year Report"//Report name
  Select_Year:"Sélectionner l'Année", //"Select Year"//Search criteria
  Select_Vehicles:"Sélectionner Véhicules",//"Select Vehicles" //Search criteria
  Select_Vehicle:"Sélectionner Véhicule",//"Select Vehicles" //Search criteria
  Select_Product:"Sélectionner Produi",
  Select_User:"Seélectionner Utilisateur", //"Select User"//Search criteria
  Search:"Rechercher",//"Search" //Buton label

  Pump_Maintenance_Report:"Rapport de Maintenance de Pompe",//"Pump Maintenance Report"
  History:"Histoire", //"History"//Table heading for Maintenance History
  Add_Service_Record:"Ajouter Enregistrement de Service", //"Add Service Record"//Mantenance Service record
  Service_Date:"Date de Service",//"Service Date",
  Technician:"Technicien", //"Technician" //Who did the work
  Hours_to_Next_Service:"Heures Avant le Prochain Service",//"Hours to Next Service"
  Service_Hours:"Heures de Services", //"Service Hours",
  Hours:"Heures", //"Hours",
  Total_Hours:"Heures Totales",//"Total Hours",
  Pump_Hours:"Heures de Pompe",//"Pump Hours",
  Last_Service:"Dernier Service",//"Last Service"
  Hours_Since_Service:"Heures Depuis le Dernier Service",//"Hours Since Service"
  Remaining_Hours:"Heures Restantes",//"Remaining Hours",
  Days_to_Service:"Jours Avant Service",//"Days to Service"
  No_Services:"N° Services",//"No Services",
  Avg_Hours_Day:"Moyenne_Heures_Jours",//"Avg_Hours_Day",
  Over_Due:"En Retard",//"Over Due",

  Monthly_Summary:"Résumé Mensuel",//"Monthly Summary",
  TimeLine:"Chronologie", //"TimeLine",

  Speeding_Report:"Rapport de Vitesse",//"Speeding Report",//Report Heading
  Speeding_Events:"Événements de Vitesse",//"Speeding Events",//Table heading
  Enter:"Entrer", //"Enter"//Time you entered a Sites/Zone
  Exit:"Sortie", //"Exit",//Time you leaved a Sites/Zone
  Details:"Détails",//"Details"//Description
  Severity:"Gravité",//"Severity" //How bad was this event
  Severity_Total:"Gravité Total",//"Severity Total"
  Severity_Day:"Jour de Gravité",//"Severity Day",
  Active_Days:"Jours Actif",//"Active Days"//Number of days this truck was used in a period

  //Import patterns
  Delete:"Effacer",//"Delete",
  Import_New_Pattern:"Importer Nouveau Patron",//"Import New Pattern",
  Drop_CSV_file_here_or_click_to_Browse:"Insérer fichier CSV ici ou clicker pour feuilleter",//"Drop CSV file here or click to Browse",
  Holes_Loaded:"Trous Chargers",//"Holes Loaded",
  Error:"Erreur",//"Error"
  UserName:"Nom d'utilisateur",//"User Name"

  //Notifictions
  Add_Notification:"Ajouter Notification",//"Add Notification",
  Setup_Notifications:"Setup Notifications",
  Add:"Ajouter",//"Add",
  Title:"Titre",//"Title",
  Destination:"Destination",
  Frequency:"Fréquence", //"Frequency",
  Daily:"Quotidien",//"Daily",
  Weekdays:"Jours de la semaine",//"Weekdays",
  Monthly:"Mensuelle",//"Monthly",
  Site_Month:"Site Mois",//"Site Month",
  Site_Day:"Site Jour",//"Site Day",
  TIZ_Month:"TIZ Mois",//"TIZ Month"
  Hole_Issues:"Problèmes de Trous",//"Hole Issues",
  Hole_Fill:"Remplir Trou",//"Hole Fill",
  Maintenance:"Maintenance",

  Setup_People:"Setup Personnel",//"Setup People",
  Add_New_Person:"Ajouter un Nouveau Personnel",//"Add New Person",
  Name:"Nom",//"Name", //Name of person
  Enter_Name:"Entrez le Nom",//"Enter Name",
  Type:"Type", //Driver or Blaster

  Setup_Products:"Setup Produits", //"Setup Products",
  Product_Description:"Description de Produit",//"Product Description"
  Add_New_Product:"Entrez la Description du Produit",//"Enter Product Description"

  Vehicle_Setup:"Setup fu Véhicule",//"Vehicle Setup",
  Last_Download:"Dernier Téléchargement",//"Last Download",
  Hardware_Id:"Hardware Id",//"Hardware Id",
  Pulses_Rev:"Pulses/Rev",
  Pump_Service_Hours:"Heures Maint Pompe", //"Pump Maint Hours" //Pump Service interval
  OTA_Version:"OTA Version",
  Hose_Reel_Sensor:"Capteur de Dévidoir", //"Hose Reel Sensor",
  Installed:"Installé",//"Installed"
  Snap_Positions:"Snap Positions",
  Yes:"Oui",//"Yes",
  No:"Non",//"No",
  Active:"Actif",//"Active",
  Inactive:"Inactif",//"Inactive",

  Save:"Sauvegarder",//"Save" //Button to Save
  Zone_Edit:"Zone Edit", //Heading for box with Zone edit details
  Loading_Zone:"Zone de Chargement", //"Loading Zone"//A zone where the truck will load. Also have other zone types like Speed Zone

  //Short and long wedday names
  Sunday:"Dimanche",//"Sunday",
  Monday:"Lundi",//"Monday",
  Tuesday:"Mardi",//"Tuesday",
  Wednesday:"Mercredi",//"Wednesday",
  Thursday:"Jeudi",//"Thursday",
  Friday:"Vendredi",//"Friday",
  Saturday:"Samedi", //"Saturday",
  Sun:"Dim",//"Son",
  Mon:"Lun",//"Mon",
  Tue:"Mar",//"Tue",
  Wed:"Mer",//"Wed",
  Thur:"Jeu",//"Thur",
  Fri:"Vend",//"Fri",
  Sat:"Sam",//"Sat"

  //Short Month Names  
  Jan:"Jan",
  Feb:"Fév",//"Feb",
  Mar:"Mars",
  Apr:"Avr",//"Apr",
  May:"Mai",//"May",
  Jun:"Juin",//"Jun",
  Jul:"Juil",//"Jul",
  Aug:"Août",//"Aug",
  Sep:"Sep",
  Oct:"Oct",
  Nov:"Nov",
  Dec:"Déc",//"Dec",

  //Hole States
  Wet:"Mouillé",//"Wet",
  Decked:"Decked",
  ReDrill:"Re-percer",//"ReDrill",
  Cracked:"Fendu",//"Cracked",
  TopLoaded:"Chargé du Top",//"TopLoaded",
  Collapsed:"Effondré",//"Collapsed",
  CleanOut:"Nettoyer",//"CleanOut",
  OverBurden:"Plus de fardeau",//"OverBurden",
  Tacked:"Tacked",
  OverLoaded:"Trop Rempli",//"OverLoaded",
  UnderLoaded:"Pas Asser Rempli",//"UnderLoaded",
  Hot:"Hot",//"Hot",

  //General Text
  IBX_Install_Manual: "IBX Installation Guide",
  Reach_Setup_Manual: "EMLID Reach RS Integration Guide",
  Android_Applications:"Applications Android",//"Android Applications",
  Android_Install_Application_Version:"Version d'Application Android à Installer",//"Android Install Application Version",
  Android_Application_Version:"Version de iBlastX pour Application Android",//"Android Driver Application Version"
  File_Upload_Failed:"Téléchargement du Fichier a Échoué!", //"File Upload Failed!"
  File_Upload_Already_in_Progress:"Téléchargement du Fichier Déjà en Cours", //"File Upload Already in Progress!",
  Drag_and_drop_some_files_here_or_click_to_select_files:"Insérer des fichiers ici ou cliquez pour sélectionner un fichier",//"Drag and drop some files here or, click to select files"
  Bad_Hose_Reel_Sensor:"Capteur de Dévidoir Défectueux", //"Bad Hose Reel Sensor",
  No_Communication:"Aucune Communication",//"No Communication",

  Survey_None:"None",
  Survey_Depth_OK:"Depth OK",
  Survey_Depth_Over:"Depth +",
  Survey_Depth_Under:"Depth -",
  Survey_Loaded:"Loaded",
  Survey_Stemming_OK:"Stemming OK",
  Survey_Stemming_Over:"Stemming +",
  Survey_Stemming_Under:"Stemming -",

  Depth_Tol:"Depth Tolerance(%)",
  Stemming_Tol:"Stemming Tolerance(%)",

  Add_Load:"Add Load",
  Add_Design:"Add Design",
  Design_Edit:"Design Edit",

  SurveyLatest   :"Latest",
  SurveyDepth    :"Depth",
  SurveyLoaded   :"Loaded",
  SurveyStemming :"Stemming",
  SurveyWaterDepth :"Water Depth",
  SurveyTemperature :"Températur",

  SurveyPostDriling :"Post Drilling",
  SurveyPreLoading  :"Pre Loading",
  SurveyPostLoading :"Post Loading",
  SurveyPreStemming :"Pre Stemming",

  Survey_Loading     : "Loading",
  Survey_PostDrill   : "Post Drill",
  Survey_PreLoading  : "Pre Loading",
  Survey_PostLoading : "Post Loading",
  Survey_PreStemming : "Pre Stemming",

  OverFillDesign :"Design",
  OverFillSurvey :"Survey",
  OverFillIdeal  :"Ideal",

  Unkown_Holes : "Unkown Holes",
  No_Data_Found : "No Data Found",

  Setup_Tablets : "Tablet Setup",
  Connected     : "Connected",
  Registered    : "Registered",
  Version       : "Version",
  Mode          : "Mode",
  Tablets       : "Tablets",

  Accessories : "Accessories",
  Add_New_Accessory : "Add New Accessory",
  Setup_Accessories : "Setup Accessories",
  Accessory_Summary : "Accessory Summary",
  Accessory_Description : "Accessory Description",
  Booster : "Booster",
  Detonator : "Detonator",
  Other : "Other",
  Barcode : "Barcode",
  ExportId : "Export Id",

  Total_Weight : "Total Weight",
  Start_Depth : "Start Depth",

  User_Name : "User Name",
  Email : "email",
  Timezone : "Timezone",
  Language : "Language",
  Phone_No : "Phone No",
  Permissions : "Permissions",
  Units : "Units",
  Last_Login : "Last Login",

  Add_New_User : "Add New User",
  New_User_Name : "New User Name",

  GPS_Position : "GPS Position",
  Image : "Image",

  Pattern_Load_Summary_Report : "Pattern Load Summary Report",

  Pattern_Load_Summary_Report : "Pattern Load Summary Report",
  DashBoard : "Dashboard",
  BlastReport_PDF : "Blast Report PDF",
  DrillReport_PDF : "Drill Report PDF",
  Phase : "QAQC Phase",
  Start : "Start",
  StartDepth : "Start Depth",
  ColumnHeight : "Column",
  StemmingHeight : "Stemming",

  OverDrill : "OverDrill",
  PostDrill : "PostDrill",
  PreLoad   : "PreLoad",

};

